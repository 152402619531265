* {
  box-sizing: border-box;
}

main {
  /*width: 100%;*/
  border: 1px solid #e0e0e0;
  margin: 0 auto;
  display: flex;
  flex-grow: 1;
}

.tools-block {
  min-height: 80px;
  float: left;
  padding-left: 10px;
}

#paint-block {
  max-width: 240px;
  height: 350px;
}

#paint-canvas {
  /*width: %;*/

  border: 1px solid #000;
  cursor:crosshair;
}

#painting-info-message {
  overflow-wrap: break-word;
  overflow-x: hidden;
  overflow-y: auto;
  /*text-align:justify;*/
  max-height: 460px;
  /* automatically scroll to bottom if user not scrolled up*/
  flex-direction: column-reverse;
  display: flex;
}
