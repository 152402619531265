.comment-block {
  border: 1px solid black;
  margin: 3px;
  padding-left: 5px;
}

.comment-block-username {
  font-size: small;
  font-weight: bold;
}

.comment-block-content {
  color: grey;
}

.comment-block-time {
  color: red;
  font-size: small;
}