body {
  background-image: url("/public/banana.jpg");
  padding: 20px;
  margin: 0;
}

h1, h2, p, ul, li {
  font-family: sans-serif;
  word-wrap: break-word;
}

p[class='post-timestamp'] {
  color: grey;
  font-size: small;
}

table {
  border-collapse: collapse;
}

th {
  background-color: #4CAF50;
}

th, td {
  border: 1px solid #000;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

ul.menu-list {
  background-color: #111;
  height: max-content;
  padding: 0;
}

ul.menu-list .menu-item-left {
  float: right;
  display: inline-table;
  background-color: black;
}

ul.menu-list li {
  display: inline;
  list-style-type: none;
  margin: 0;
}

ul.menu-list li a {
  color: #FFF;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}

div#theme-select-container {
  position: absolute;
  top: 0;
  right: 0;
}

#theme-select-container ul.line, .hidden li {
  display: inline-block;
  list-style-type: disc;
}
#theme-select-container ul.hidden li input {
  display: none;
}

#theme-select-container ul.hidden li label {
  padding: 10px;
  background-color: aliceblue;
}

#theme-select-container ul.hidden li input[type="radio"]:checked + label {
  color: blue;

}

.active {
  background-color: #0099FF;
}

.content {
  /*background-color: #ffffa4;*/
  background-color: #FFFFFF;
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

.content-holder {
  opacity: 1 !important;
}

.content h2 {
  padding: 0;
  margin: 0;
}

.content li {
  margin-bottom: 10px;
}

.header {
  text-shadow: 0px 0px 10px #FFFFFF
}

.div-post {
  margin: 10px;
  padding: 10px;
  border: #000 solid 1px;
  background-color: #f47da070;
}

/* For Post page*/

input, textarea {
  width: 100%;
  width: -moz-available;          /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  display: block;
  margin: 10px;
  padding: 10px;
  resize: none;
}

textarea[id=content] {
  height: 300px;
}

textarea[id=tags] {
  color: #00a;
}

textarea[id='comment.content'] {
  height: 100px;
}


.tag-item-element {
  margin: 3px;
  padding: 2px 10px;
  float: left;
  cursor: pointer;
}

.post-content-preview {
  /*Multi-Line Truncation with Pure CSS*/
  /*https://css-tricks.com/multi-line-truncation-with-pure-css/*/
  --max-lines: 2;
  --lh: 1.4rem;
  line-height: var(--lh);
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
  /* uncomment 2 next lines to add 3 dots at the end of each string */
  /*white-space: pre;*/
  /*text-overflow: ellipsis;*/
}

.post-modified-span {
  color: #f00;
  float: right;
}